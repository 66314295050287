import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme";
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { navigate } from 'gatsby-link';


import { Map, Marker } from 'pigeon-maps';

import { encode } from '../utils/util';



const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 200px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 150px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`

const ContactSection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;
  /* padding-left: 80px; */

  @media ${props => props.theme.breaks.down('md')} {

    .title-cond {
      width: 90% !important;
    }
  }
`

const ContactContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 7rem;
  text-align: left;
  position: relative;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }
`


const Caption = styled.h3`
  color: ${props => props.color === 'w' ? props.theme.colors.primary : props.theme.colors.body};
  text-align: left;

  margin-top: ${props => props.color !== 'w' ? '30px' : 0};
`

const Form = styled.form`
  margin: 0 0 0 40px;
  color: ${props => props.theme.colors.body};

  & * {
    color: ${props => props.theme.colors.body};
  }

  .input-grid {
    margin-top: 30px;
  }

  .input-grid:first-child {
    margin-top: 0;
  }

  .MuiFormLabel-root {
  color: ${props => props.theme.colors.body};
  }

  .MuiInputBase-input {
    border-bottom: 2px solid ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.primary};
  }

  textarea.MuiInputBase-input {
    border: none;
  }

  @media ${props => props.theme.breaks.down('md')} {
    margin-left: 0;
  }
`

const Button = styled.button`
  display: block;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 100%;
  border: none;
  padding: 15px 0;
  margin-top: 60px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`

const Address = styled.address`
  color: ${props => props.theme.colors.primary};
  font-weight: 300;
  display: block;
  width: 200px;
  font-style: initial;
  margin-top: 30px;

  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`

const MapContainer = styled.div`
  height: 180px;
  width: 300px;
  background-color: green;
  margin-top: 30px;

  @media ${props => props.theme.breaks.down('md')} {
    width: 100%;
    height: 250px;
  }
`

const Tele = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-weight: 300;

  a {
    font-size: inherit;
    color: inherit;
  }

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 50px;
  }
`

const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 60px 0 0 0;
  padding-left: 40px;
  position: relative;
  background-color: ${props => props.theme.colors.dark};

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;

  }
`


const ContactUsPage = ({ data }) => {

  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  const redirect = (location) => {
    window.location.href = location
  }

  return (
    
      <Layout color={theme.colors.body} pageTitle="Contact Us">
      <Container>
        
        <ContactSection className="contact-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>contact us </TitleCond>
          </div>
          <ContactContainer>

          <Grid container alignItems="flex-start" spacing={4}>

           <Grid item xs={12} md={4}>
            <Caption color="w">Difference makes all the difference.</Caption>
            <Caption>How can Celebrand make a difference in your life?</Caption>
           </Grid>

           <Grid item xs={12} md={8}>
             <Form
              name="contact-us"
              method="post"
              action="/submitted"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              >
               <input type="hidden" name="form-name" value="contact-us" />
               <p hidden>
                 <label>
                   Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                 </label>
               </p>
               <Grid container>
                 <Grid item xs={12} className="input-grid">

                    <TextField fullWidth label="Name" placeholder="Name" color="secondary" name="name" value={state.name} onChange={handleChange} required></TextField>
                    
                  </Grid>
                  <Grid item xs={12} className="input-grid">

                    <TextField fullWidth name="email" label="Email" placeholder="Email" type="email" color="secondary" value={state.email} onChange={handleChange} required></TextField>
                    
                  </Grid>
                  <Grid item xs={12} className="input-grid">
                   
                    <TextField fullWidth name="business" label="Business" placeholder="Business" color="secondary" value={state.business} onChange={handleChange} ></TextField>

                  </Grid>
                  <Grid item xs={12} className="input-grid">

                    <TextField fullWidth name="message" label="Message" placeholder="Message" color="secondary" value={state.message} onChange={handleChange} required></TextField>

                  </Grid>
                  <Grid item xs={12}>
                    <Button>Submit</Button>
                  </Grid>

                  <Grid item xs md={7}>
                  </Grid>
                   
                  <Grid item xs={12} md={5}> <Address><a href="https://www.google.com/maps/place/59+Gilbert+St,+Monroe,+NY+10950/@41.3359285,-74.1958619,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2d64ab1d2065b:0x14e17b40cdfb3c4f!8m2!3d41.3359285!4d-74.1936732">59 Gilbert Street, Suite 102 Monroe, NY 10950</a></Address></Grid>
                </Grid>

             </Form> 
            </Grid>

          </Grid>

          <Grid container alignItems="flex-end">
            <Grid item xs={12} md={4}>
              <Tele><a href="tel:845-442-2000">845.442.2000</a></Tele>
            </Grid>
            <Grid item xs md={4}></Grid>

            <Grid item xs={10} md={4}>
              <Grid item xs md={6}></Grid>
                  <Grid item xs={12} md={8}>
                    <MapContainer>
                      <Map provider={(x, y, z, dpr) => { 
                          return `https://a.tile.openstreetmap.fr/hot/${z}/${x}/${y}.png`}} 
                        center={[41.33617820696798, -74.19324404836026]} zoom={9} defaultWidth={800}
                        // onClick={() => {
                        //   redirect() 41.33617820696798, -74.19324404836026
                        // }}
                        >

                      <Marker anchor={[41.33617820696798, -74.19324404836026]} payload={1} onClick={({ e, anchor, payload }) => {
                        redirect("https://www.google.com/maps/place/59+Gilbert+St,+Monroe,+NY+10950/@41.3359285,-74.1958619,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2d64ab1d2065b:0x14e17b40cdfb3c4f!8m2!3d41.3359285!4d-74.1936732")
                        }} />

                      </Map>  
                    </MapContainer>
                  </Grid>
            </Grid>
          </Grid>


          </ContactContainer>


          

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

        </ContactSection>

        
      </Container>
      </Layout>
    
)
}

export default ContactUsPage;